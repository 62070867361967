import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import AuthContext from '../context/AuthContext';
import { TextField, Button, List, ListItem, ListItemText, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { supabase } from '../supabaseClient';

const Account = () => {
    const { user } = useContext(AuthContext);
    const [userData, setUserData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        address_line1: '',
        address_line2: '',
        city: '',
        state: '',
        postal_code: '',
        country: '',
    });
    const [wifiCredentials, setWifiCredentials] = useState([]);
    const [newWifiCredential, setNewWifiCredential] = useState({ ssid: '', password: '' });
    const [userExists, setUserExists] = useState(false);
    const [showPassword, setShowPassword] = useState({}); // State to manage password visibility

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const { data } = await supabase.auth.getSession();
                const token = data.session?.access_token;
                const response = await axios.get('https://api.asksafari.com/users/user-details/', {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                });

                if (response.data.user) {
                    setUserData(response.data.user);
                    setUserExists(true);
                    // Fetch WiFi credentials after confirming user existence
                    await fetchWifiCredentials(token);
                } else {
                    console.log('User not found, creating user...');
                    setUserExists(false);
                }
            } catch (error) {
                console.error('Error fetching user data', error);
                setUserExists(false);
            }
        };

        fetchUserData();
    }, []);

    const fetchWifiCredentials = async (token) => {
        try {
            const response = await axios.get('https://api.asksafari.com/wifi/get-wifi-credentials/', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            setWifiCredentials(response.data.wifi_credentials || []);
        } catch (error) {
            console.error('Error fetching WiFi credentials', error);
        }
    };

    const handleCreateUser = async (token) => {
        try {
            const userInfo = {
                first_name: userData.first_name, 
                last_name: userData.last_name, 
                email: userData.email,
                phone_number: userData.phone_number, 
                address_line1: userData.address_line1, 
                address_line2: userData.address_line2,
                city: userData.city,             
                state: userData.state,          
                postal_code: userData.postal_code, 
                country: userData.country 
            };

            await axios.post('https://api.asksafari.com/users/create-user/', userInfo, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });

            setUserData(userInfo);
            setUserExists(true);
            // Fetch WiFi credentials after creating user
            await fetchWifiCredentials(token);
        } catch (error) {
            console.error('Error creating user', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserData({ ...userData, [name]: value });
    };

    const handleWifiInputChange = (e) => {
        const { name, value } = e.target;
        setNewWifiCredential({ ...newWifiCredential, [name]: value });
    };

    const handleUpdateUser = async () => {
        try {
            const { data } = await supabase.auth.getSession();
            const token = data.session?.access_token;

            if (userExists) {
                const response = await axios.put('https://api.asksafari.com/users/update-user/', userData, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                });
                console.log('Update response:', response);
            } else {
                await handleCreateUser(token);
            }
        } catch (error) {
            console.error('Error updating user data', error);
        }
    };

    const handleAddWifiCredential = async () => {
        try {
            const { data } = await supabase.auth.getSession();
            const token = data.session?.access_token;
            await axios.post('https://api.asksafari.com/wifi/add-wifi-credential/', newWifiCredential, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            setWifiCredentials([...wifiCredentials, newWifiCredential]);
            setNewWifiCredential({ ssid: '', password: '' });
        } catch (error) {
            console.error('Error adding WiFi credential', error);
        }
    };

    const handleDeleteWifiCredential = async (ssid) => {
        try {
            const { data } = await supabase.auth.getSession();
            const token = data.session?.access_token;
            await axios.delete('https://api.asksafari.com/wifi/delete-wifi-credential/', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                data: { ssid }
            });
            setWifiCredentials(wifiCredentials.filter(cred => cred.ssid !== ssid));
        } catch (error) {
            console.error('Error deleting WiFi credential', error);
        }
    };

    const togglePasswordVisibility = (ssid) => {
        setShowPassword((prevState) => ({
            ...prevState,
            [ssid]: !prevState[ssid],
        }));
    };

    return (
        <div>
            <Typography variant="h5">Account Information</Typography>
            <form>
                <TextField name="first_name" label="First Name" value={userData.first_name} onChange={handleInputChange} fullWidth margin="normal" />
                <TextField name="last_name" label="Last Name" value={userData.last_name} onChange={handleInputChange} fullWidth margin="normal" />
                <TextField name="email" label="Email" value={userData.email} onChange={handleInputChange} fullWidth margin="normal" />
                <TextField name="phone_number" label="Phone Number" value={userData.phone_number} onChange={handleInputChange} fullWidth margin="normal" />
                <TextField name="address_line1" label="Address Line 1" value={userData.address_line1} onChange={handleInputChange} fullWidth margin="normal" />
                <TextField name="address_line2" label="Address Line 2" value={userData.address_line2} onChange={handleInputChange} fullWidth margin="normal" />
                <TextField name="city" label="City" value={userData.city} onChange={handleInputChange} fullWidth margin="normal" />
                <TextField name="state" label="State" value={userData.state} onChange={handleInputChange} fullWidth margin="normal" />
                <TextField name="postal_code" label="Postal Code" value={userData.postal_code} onChange={handleInputChange} fullWidth margin="normal" />
                <TextField name="country" label="Country" value={userData.country} onChange={handleInputChange} fullWidth margin="normal" />
                <Button variant="contained" color="primary" onClick={handleUpdateUser}>Update Profile</Button>
            </form>

            <Typography variant="h5" style={{ marginTop: '2rem' }}>WiFi Credentials</Typography>
            <List>
                {wifiCredentials.map((cred, index) => (
                    <ListItem key={index}>
                        <ListItemText
                            primary={cred.ssid}
                            secondary={
                                showPassword[cred.ssid] ? cred.password : '********'
                            }
                        />
                        <IconButton edge="end" aria-label="toggle visibility" onClick={() => togglePasswordVisibility(cred.ssid)}>
                            {showPassword[cred.ssid] ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                        <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteWifiCredential(cred.ssid)}>
                            <DeleteIcon />
                        </IconButton>
                    </ListItem>
                ))}
            </List>
            <TextField name="ssid" label="SSID" value={newWifiCredential.ssid} onChange={handleWifiInputChange} fullWidth margin="normal" />
            <TextField name="password" label="Password" value={newWifiCredential.password} onChange={handleWifiInputChange} fullWidth margin="normal" />
            <Button variant="contained" color="primary" onClick={handleAddWifiCredential}>Add WiFi</Button>
        </div>
    );
};

export default Account;
