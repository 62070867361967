// src/components/Home.js
import React from 'react';
import { Container, Typography } from '@mui/material';

const Home = () => {
  return (
    <Container maxWidth="sm" style={{ marginTop: '2rem' }}>
      <Typography variant="h6" gutterBottom>
        Thank you for trying Safari!
      </Typography>
      <Typography variant="body1">
        We believe this device represents a step toward the future of learning and development for young children, and we're excited to have you as part of this journey. Today, the application is very basic, but we are adding new functionality every day. We'll appreciate any feedback or ideas you might have to improve the experience for yourself or your little one. Thanks again!
      </Typography>
    </Container>
  );
};

export default Home;
