import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import AuthContext from '../context/AuthContext';
import { List, ListItem, ListItemText, Box, Typography } from '@mui/material';
import { supabase } from '../supabaseClient';

const History = () => {
  const { user } = useContext(AuthContext);
  const [interactionHistory, setInteractionHistory] = useState([]);

  useEffect(() => {
    const fetchInteractionHistory = async () => {
      try {
        const { data } = await supabase.auth.getSession();
        const token = data.session?.access_token;

        if (!token) {
          throw new Error("User is not authenticated");
        }

        const response = await axios.get('https://api.asksafari.com/interactions/interaction-history/10', {
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        });

        setInteractionHistory(response.data);
      } catch (error) {
        console.error('Error fetching interaction history:', error);
      }
    };

    if (user) {
      fetchInteractionHistory();
    }
  }, [user]);

  return (
    <Box p={3}>
      <Typography variant="h6" gutterBottom>
        Interaction History
      </Typography>
      <List>
        {interactionHistory.map((interaction, index) => (
          <ListItem key={index} alignItems="flex-start">
            <ListItemText
              primary={
                <>
                  <strong>Prompt:</strong> {interaction.system_prompt}<br />
                  <strong>User:</strong> {interaction.user_input}<br />
                  <strong>Response:</strong> {interaction.bot_response}
                </>
              }
              secondary={
                <>
                  <strong>Start:</strong> {new Date(interaction.start_timestamp).toLocaleString()}<br />
                  <strong>Finish:</strong> {new Date(interaction.finish_timestamp).toLocaleString()}
                </>
              }
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default History;