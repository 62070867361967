import React, { useState, useEffect, useContext } from 'react';
import { supabase } from '../supabaseClient';
import axios from 'axios';
import AuthContext from '../context/AuthContext';
import { TextField, Button, Box, Typography } from '@mui/material';

const Settings = () => {
    const { user } = useContext(AuthContext);
    const [systemPrompt, setSystemPrompt] = useState('');

    useEffect(() => {
        const fetchSettings = async () => {
            if (user) {
                try {
                    const { data } = await supabase.auth.getSession();
                    const token = data.session?.access_token;
                    const response = await axios.get('https://api.asksafari.com/interactions/interaction-settings/', {
                        headers: { 'Authorization': `Bearer ${token}` },
                    });
                    setSystemPrompt(response.data.system_prompt);
                } catch (error) {
                    console.error('Error fetching settings', error);
                }
            }
        };

        fetchSettings();
    }, [user]);

    const updateSystemPrompt = async () => {
        try {
            const { data } = await supabase.auth.getSession();
            const token = data.session?.access_token;
            await axios.post('https://api.asksafari.com/interactions/interaction-settings/', { system_prompt: systemPrompt }, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            alert('Settings updated successfully');
        } catch (error) {
            console.error('Error updating settings', error);
            alert('Error updating settings');
        }
    };

    return (
        <Box p={3}>
            <Typography variant="h6" gutterBottom>
                Settings
            </Typography>
            <TextField
                label="System Prompt"
                multiline
                rows={4}
                fullWidth
                value={systemPrompt}
                onChange={(e) => setSystemPrompt(e.target.value)}
                margin="normal"
            />
            <Button variant="contained" color="primary" fullWidth onClick={updateSystemPrompt} style={{ marginTop: '1rem' }}>
                Save
            </Button>
        </Box>
    );
};

export default Settings;